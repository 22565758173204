<template>
    <Section width="sm">
        <h2
            v-if="$helpers.filterEmptyElements(data.primary.h2)"
            class="section-header"
        >
            {{ $prismic.asText(data.primary.h2) }}
        </h2>

        <div
            class="section-teaser"
            v-html="$prismic.asHtml(data.primary.teaser)"
        />

        <List :data="data.items" />
    </Section>
</template>

<script>
import List from './HighlightLendersList.vue';
export default {
    components: {
        List
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
    section::v-deep {

        .section-inner {

            .section-teaser {
                @include spacing(margin,6,bottom);
            }
        }
    }
</style>
