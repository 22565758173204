<template>
    <Section width="lg">
        <div class="section-heading">
            <h2 v-if="$validateText(data.primary.h2)" class="section-header">
                {{ $prismic.asText(data.primary.h2) }}
            </h2>
            <div 
                v-if="$validateText(data.primary.teaser)" 
                class="rich-text"
                v-html="$prismic.asHtml(data.primary.teaser)" 
            />
        </div>
        <div class="calculator-container">
            <div class="calculator-box calculations">
                <h3>{{ inputSectionHeader }}</h3>
                <car-loan-amount-sliders 
                    v-if="data.primary.loan_type === 'car_loan'" 
                    @updateLoanAmount="(value) => loanAmount = value" 
                />
                <Slider
                    v-else-if="data.primary.loan_type === 'private_loan'"
                    :label="$translate('calc_private_loan_amout_label', 'Lånebelopp')"
                    :min-value="0"
                    :max-value="600000"
                    :max-fallback="1000000"
                    increment="1000"
                    suffix="kr"
                    calc-key="loanAmount"
                    :start-value="20000"
                    @updateSliderValue="setValues"
                />
                <Slider
                    :label="$translate('calc_interest_label', 'Ränta')"
                    :min-value="0"
                    :max-value="50"
                    :max-fallback="100"
                    :max-decimals="2"
                    :start-value="6"
                    increment="1"
                    suffix="%"
                    calc-key="interest"
                    @updateSliderValue="setValues"
                />
                <Slider
                    :label="$translate('calc_duration_label', 'Löptid')"
                    :min-value="1"
                    :max-value="180"
                    :max-fallback="200"
                    :start-value="60"
                    increment="1"
                    suffix="mån"
                    calc-key="duration"
                    @updateSliderValue="setValues"
                />
            </div>
            <div class="calculator-box result bg-blue">
                <h3>{{ $translate('calc_result_section_h3', 'Resultat') }}</h3>
                <div class="result-body">
                    <div v-if="data.primary.loan_type === 'car_loan'" class="result-item">
                        <span>{{ $translate('calc_result_loan_amount', 'Lånebelopp') }}</span>
                        <span>{{ $format.currency(loanAmount) }}</span>
                    </div>
                    <div class="result-item">
                        <span>{{ $translate('calc_result_interest_cost', 'Räntekostnad') }}</span>
                        <span>{{ $format.currency(interestCost) }}</span>
                    </div>
                    <div class="result-item">
                        <span>{{ $translate('calc_result_interest_deduction', 'Ränteavdrag') }}</span>
                        <span>{{ $format.currency(interestDeduction * -1) }}</span>
                    </div>
                    <div class="result-item">
                        <span>{{ $translate('calc_result_total_to_repay', 'Total återbetalning') }}</span>
                        <span>{{ $format.currency(totalToPay) }}</span>
                    </div>
                    <div class="result-item result-highlighted">
                        <span>{{ $translate('calc_result_monthly_payment', 'Månadsbetalning') }}</span>
                        <span>{{ $format.currency(monthlyPayments) }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="details-container">
            <div class="switch-container">
                <switch-checkbox @updateValue="(value) => showTable = value" />
                <span>{{ $translate('calc_show_payment_breakdown', 'Visa betalnings nedbrytning') }}</span>
            </div>
            <details-table
                v-if="showTable"
                :loan-amount="loanAmount"
                :duration="duration"
                :interest="interest"
            />
        </div>
    </Section>
</template>

<script>
import Slider from '@/components/features/SliderAndInput.vue';
import CarLoanAmountSliders from './CarLoanAmountSliders.vue';
import DetailsTable from '@/components/slices/calculator/DetailsTable.vue';
import SwitchCheckbox from '@/components/features/SwitchCheckbox.vue';
export default {
    components: {
        Slider,
        CarLoanAmountSliders,
        DetailsTable,
        SwitchCheckbox
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            interest: 6,
            duration: 60,
            showTable: false,
            loanAmount: 20000,
        };
    },
    computed: {
        inputSectionHeader() {
            if (this.data.primary.loan_type === 'car_loan') {
                return this.$translate('calc_car_input_section_h3', 'Ditt lån');
            }
            return this.$translate('calc_private_loan_input_section_h3', 'Ditt lån');
        },
        interestCost() {
            const monthlyInterestRate = (this.interest/100) / 12;
            let currentCost = 0;
            for (let i = this.duration; i > 0; i--) {
                currentCost += this.loanAmount * (i/this.duration) * monthlyInterestRate;
            }
            return currentCost;
        },
        interestDeduction() {
            if (this.$isNorway()) {
                return this.interestCost * 0.22;
            }
            return this.interestCost * 0.3;
        },
        totalToPay() {
            return this.loanAmount + (this.interestCost - this.interestDeduction);
        },
        monthlyPayments() {
            return this.totalToPay / this.duration;
        },
    },
    methods: {
        setValues(data) {
            this[data.key] = data.value;
        },
    }
};
</script>

<style lang="scss" scoped>
    .section-heading{
        margin-bottom: 2rem;
    }
    .calculator-container{
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
        @include device(pad) {
            grid-template-columns: 3fr 2fr;
            gap: 1rem;
        }
        .calculator-box {
            box-shadow: $bxs;
            @include spacing(padding, 4);
            @include border-radius(3px);
            h3 {
                text-align: center;
            }
        }
        .result {
            color: $white;
            .result-body {
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                .result-item {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    gap: 0.5rem;
                    font-size: 18px;
                    @include border(top);
                    padding-top: 1rem;
                    @include device(pad) {
                        flex-wrap: nowrap;
                    }
                    &:first-child {
                        padding-top: 0;
                        border-top: unset;
                    }
                    &.result-highlighted {
                        font-size: 20px;
                        font-weight: bold;
                        border-width: 2px;
                    }
                }
            }
        }
    }
    .details-container {
        margin-top: 20px;
        @include border-radius(3px);
        box-shadow: $bxs;
        .switch-container {
            @include spacing(padding, 4);
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }
    }
</style>