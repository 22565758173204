<template>
    <div class="filter-container">
        <div class="search">
            <input 
                ref="searchInput"
                v-model="query"
                type="text"
                name="search"
                :placeholder="$translate('input_search', 'Sök...')"
                @keydown.esc="clearQuery()"
            >
            <i v-if="query.length" class="fa-regular fa-xmark clear-button" role="button" @click="clearQuery()" />
        </div>
        <div v-if="filters" class="filter">
            <button 
                key="all" 
                class="filter-item"
                :class="{'active': activeFilter === 'all'}"
                @click="$emit('setFilter', 'all')"
            >
                {{ $translate('qa_all', 'Alla') }}
            </button>
            <button
                v-for="(filter, index) in filters"
                :key="index"
                class="filter-item"
                :class="{'active': activeFilter === filter}"
                @click="$emit('setFilter', filter)"
            >
                {{ filter }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        filters: {
            type: Array,
            required: false,
            default: null
        },
        activeFilter: {
            type: String,
            required: false,
            default: 'all'
        }
    },
    data() {
        return {
            query: ''
        };
    },
    watch: {
        query() {
            this.$emit('query', this.query);
        }
    },
    methods: {
        clearQuery(){
            this.query = '';
            this.$refs.searchInput.focus();
        }
    }
};
</script>

<style lang="scss" scoped>
    .filter-container {
        @include spacing(padding,3,bottom);
    }
    .filter {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        @include spacing(padding,3,(bottom top));
        .filter-item {
            padding: 10px 16px;
            background: #f8f8f8;
            @include border-radius(5px);
            box-shadow: unset;
            color: unset;
            font-weight: unset;
            font-size: 16px;
            transform: unset;

            &:hover, 
            &.active {
                color: $white;
                background: $green;
            }
        }
    }
    .search {
        position: relative;
        input {
            @include border-radius(5px);
            @include border;
            font-family: $fontText;
            font-size: 16px;
            padding: 15px;
            width: 100%;
            padding-right: 45px;
        }
        .clear-button {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            font-size: 28px;
            right: 10px;
            cursor: pointer;
            color: #999;
            &:hover { color: $font;}
        }
    }
</style>