<template>
    <div>
        <Slider
            :label="$translate('calc_car_cost_label', 'Bilens kostnad')"
            :min-value="0"
            :max-value="1000000"
            :max-fallback="5000000"
            increment="10000"
            suffix="kr"
            calc-key="carCost"
            :start-value="200000"
            @updateSliderValue="setValues"
        />
        <Slider
            :label="$translate('calc_car_down_payment_label', 'Kontantinsats')"
            :min-value="20"
            :max-value="80"
            :max-fallback="99"
            :start-value="20"
            :max-decimals="2"
            increment="1"
            suffix="%"
            calc-key="downPayment"
            @updateSliderValue="setValues"
        />
    </div>
</template>

<script>
import Slider from '@/components/features/SliderAndInput.vue';

export default {
    components: {
        Slider,
    },
    data() {
        return {
            carCost: 200000,
            downPayment: 20,
        };
    },
    computed: {
        loanAmount() {
            return this.carCost * (1 - this.downPayment/100);
        },
    },
    watch: {
        loanAmount() {
            this.$emit('updateLoanAmount', this.loanAmount);
        },
    },
    methods: {
        setValues(data) {
            this[data.key] = data.value;
        },
    }
};
</script>